import { FC } from 'react';
import { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
// import Image from 'next/image';
import { useRouter } from 'next/router';
import { useAuth } from '@/hooks/useAuth';
import Cookies from 'js-cookie';
import { getCurrentYear } from '@/helpers/helpers';
import { MENU_LINKS } from '@/helpers/variables';
import styles from './Header.module.scss';

interface IHeader {
  isBottom?: boolean;
  isAuthActive: boolean;
  setAuthActive: (isAuthActive: boolean) => void;
}

const Header: FC<IHeader> = ({ isBottom, isAuthActive, setAuthActive }) => {
  const router = useRouter();
  const urlPage = router.asPath;

  const isHomePage = urlPage === '/';

  const [isActive, setIsActive] = useState(false);

  const [isClient, setIsClient] = useState(false);

  const [inputSearch, setInputSearch] = useState('');

  const [isAvtorSearch, setIsAvtorSearch] = useState(false);

  const refSearchField = useRef<HTMLDivElement>(null);

  const { user } = useAuth();

  const userName = user?.user_email || user;

  const token = Cookies.get('token');

  const currentYear = getCurrentYear();

  useEffect(() => {
    setIsClient(true);

    const handleClickOutside = (event: Event) => {
      if (refSearchField.current && refSearchField.current.contains(event.target as HTMLElement)) {
        return;
      }
      setIsActive(false);
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const addActiveClass = () => {
    setIsActive(true);
  };

  const setAuthActiveHandle = () => {
    setAuthActive(true);
  };

  const getSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchByPressEnter = (event: React.KeyboardEvent<object>) => {
    if (String(event.code) == 'Enter') {
      router.push(`/search_wide/${isAvtorSearch ? 'avtor' : 'book'}/${searchWord}`);
    }
  };

  const searchWord = inputSearch.includes(' ') ? inputSearch.split(' ').join('+') : inputSearch;

  return (
    <header className={`${styles.header} ${!!isBottom && styles.header_bottom}`}>
      {!!isBottom ? (
        <div className={styles.header__copyright}>{`© 2008 - ${currentYear} kuchaknig.org`}</div>
      ) : (
        <div className={styles.header__logo}>
          {!!isHomePage ? (
            <span className={styles.header__logoLink}>
              <img loading="lazy" className={styles.header__logoImg} src="/kuchaknig_top_fon_ru.jpg" alt="logo" width="178" height="105" />
              {/* <Image fill sizes="(max-width: 1920px) 178px, 105px, (max-width: 530px) 97px, 57px" placeholder="empty" className={styles.header__logoImg} src="/kuchaknig_top_fon_ru.jpg" alt="logo" /> */}
            </span>
          ) : (
            <Link prefetch={false} href="/" className={styles.header__logoLink}>
              <img loading="lazy" className={styles.header__logoImg} src="/kuchaknig_top_fon_ru.jpg" alt="logo" width="178" height="105" />
              {/* <Image fill sizes="(max-width: 1920px) 178px, 105px, (max-width: 530px) 97px, 57px" placeholder="empty" className={styles.header__logoImg} src="/kuchaknig_top_fon_ru.jpg" alt="logo" /> */}
            </Link>
          )}
        </div>
      )}

      <div className={styles.header__main}>
        <ul className={styles.header__menu}>
          {MENU_LINKS.map((item) => (
            <li key={item.name} className={styles.header__menuItem}>
              {urlPage === item.link ? (
                <span className={item.name === 'Подкасты' ? `${styles.header__menuLink} ${styles.header__menuLink_active} ${styles.header__menuLink_new}` : `${styles.header__menuLink} ${styles.header__menuLink_active}`}>{item.name}</span>
              ) : (
                <Link prefetch={false} className={item.name === 'Подкасты' ? `${styles.header__menuLink} ${styles.header__menuLink_new}` : `${styles.header__menuLink}`} href={item.link}>
                  {item.name}
                </Link>
              )}
            </li>
          ))}
        </ul>

        <div className={styles.header__search} ref={refSearchField}>
          <div className={styles.header__searchForm}>
            <div data-search-menu className={`${styles.header__radios} ${isActive && styles.header__radios_active}`}>
              <span className={styles.header__radiosTitle}>Поиск:</span>

              <form>
                <label className={styles.header__searchLabel}>
                  <input className={styles.header__searchInput} name="type" type="radio" defaultChecked onChange={() => setIsAvtorSearch(false)} />
                  <span className={styles.header__searchTxt}>По книге</span>
                </label>

                <label className={styles.header__searchLabel}>
                  <input className={styles.header__searchInput} name="type" type="radio" onChange={() => setIsAvtorSearch(true)} />
                  <span className={styles.header__searchTxt}>По автору</span>
                </label>
              </form>
            </div>

            <div className={styles.header__searchField}>
              <input className={styles.input} onFocus={addActiveClass} value={inputSearch} onChange={getSearchWord} placeholder="Найти книги и авторов" onKeyDown={searchByPressEnter} />

              {!!inputSearch.length ? <Link prefetch={false} href={`/search_wide/${isAvtorSearch ? 'avtor' : 'book'}/${searchWord}`} className={styles.header__searchBtn}></Link> : <span className={styles.header__searchBtn}></span>}
            </div>
          </div>
        </div>
      </div>

      {isClient && userName && token ? (
        <div className={styles.header__auth}>
          <Link prefetch={false} href="/user/settings" className={styles.header__authText}>
            {userName.slice(0, 5)}...
          </Link>
        </div>
      ) : (
        <div className={styles.header__auth} onClick={setAuthActiveHandle}>
          <span className={styles.header__authText}>Войти</span>
        </div>
      )}

      {!!isBottom && (
        <div className={styles.header__mail}>
          <div className={styles.header__bottomMenu}>
            <Link prefetch={false} className={styles.header__bottomMenuItem} target="_blank" href="/redirect_to_litres">
              Litres.ru
            </Link>
            <Link prefetch={false} className={styles.header__bottomMenuItem} target="_blank" href="/redirect_to_self_pub">
              Стать автором
            </Link>
            <Link prefetch={false} className={styles.header__bottomMenuItem} target="_blank" href="/redirect_to_my_book">
              MyBook.ru
            </Link>
          </div>
          <div className={styles.header__mailTxt}>
            <span className={styles.header__mailTxt_desktop}>По всем вопросам пишите нам на почту:</span>
            <span className={styles.header__mailTxt_mob}>Наша почта:</span>{' '}
            <a className={styles.header__mail_link} href="mailto:admin@kuchaknig.ru">
              admin@kuchaknig.ru
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
